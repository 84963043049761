#heroZone {
    margin-top: -3.2rem; 
    z-index: 400 !important;
}

#heroZone .hero-logo {
    width: 11.05rem; 
    height: 2.95rem; 
    z-index: 503; 
    margin-top: -2rem !important;
    content: url('/src/assets/img/hero-logo.svg')
}

#heroZone #hero-logo-title {
    color: #FFF ; opacity: .6;
}

#heroZone #hero-logo-text {
    color: #CCC ; 
    text-align: justify;
}

#heroZone .img-hero #hero_1 {
    position:absolute; 
    width: 13.75rem; 
    height: 8.08rem; 
    z-index: 503; 
    top: 0; 
    left: 8.52rem;
    content: url('/src/assets/img/hero_1.svg')
}

#heroZone .img-hero #hero_2 {
    position:absolute; 
    width: 13.75rem; 
    height: 7.85rem; 
    z-index: 502; 
    top: 1.76rem; 
    left: 2.94rem;
    content: url('/src/assets/img/hero_2.svg')
}

#heroZone .img-hero #hero_3 {
    position:absolute; 
    width: 13.75rem; 
    height: 7.85rem; 
    z-index: 501; 
    left: 0; 
    top: 5.09rem;
    content: url('/src/assets/img/hero_3.svg')
}

#heroZone .img-hero #hero_cell {
    position:absolute; 
    width: 20.46rem; 
    height: 11.96rem; 
    z-index: 500; 
    top: 4.92rem; 
    left: 3.30rem;
    content: url('/src/assets/img/hero_cell.svg')
}

#heroZone #start-form input {
    padding: 0.822rem 1rem;
}

#heroZone #start-form button span {
    font-family: Blogger Sans; 
    text-transform: uppercase;
}