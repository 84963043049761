
#pricesAndPlans .title{
    margin-top: 2.5rem !important;
}

#pricesAndPlans .title{
    margin-top: 2.5rem !important;
}

#pricesAndPlans #plans #plan .card {
    max-width: 20rem; 
    background-color: #2F2E3E !important; 
    box-shadow: 0 1.5rem 0.7rem rgba(0,0,0,0.2);
}

#pricesAndPlans #plans #plan #title {
    font-family:Montserrat; 
    font-size: 1rem; 
    color: #2F2E3E;
}

#pricesAndPlans #plans #plan #subtite {
    font-family:Blogger Sans; 
    color: #2F2E3E;
}

#pricesAndPlans #plans #plan button {
    font-family: Blogger Sans; 
    text-transform: uppercase;
}

#pricesAndPlans #logo {
    content: url('/src/assets/img/poweredBy.svg');
    width: 5.27rem; 
    height: 1.54rem;
}

#pricesAndPlans #plans #plan #items {
    font-family:Blogger Sans;
}

#pricesAndPlans #plans #plan #items img {
    content: url('/src/assets/img/radio.svg');
    width: 0.7rem; 
    height: 0.7rem;
}