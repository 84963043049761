
/****************Forms**********************/
form .field-has-error {
    border-color: #b7545d!important;
}

form .field-has-error:focus {
    box-shadow: 0px 0px 0px 0.2rem #e6bbbf!important; /* rgba(0,123,255,0.25);*/
}

form small.field-has-error {
    color: #b7545d!important;
}

form .text-has-error {
    color: #b7545d!important;
}