#slider #title {
    width: 85%; 
    height: auto;
    content: url('/src/assets/img/pc-mobile.svg')
}

#slider #title2 {
    margin-top: 2.5rem !important;
    color: #2F2E3E
}

#slider #sliderTryForFree {
    font-family: Blogger Sans; 
    text-transform: uppercase;
}