#headerContent {
    z-index: 999;
}

#navbarInure .navbar-brand {
    background-color: transparent;
    margin-top: -0.15rem !important; 
    box-shadow: none !important;
}

#navbarInure .navbar-brand img{
    width: 8.77rem; 
    height: 1.88rem;
    content: url('/src/assets/img/main_logo.svg');
}

#navbarInure .menu {
    margin-right: 0 !important; 
    font-family: Blogger Sans; 
    font-size: 0.9rem; 
    margin-top: 0.15rem;
}

#navbarInure .menu .menu-option span {
    color:#CCC !important;
}

#navbarInure .menu button {
    font-family: Blogger Sans; 
    text-transform: uppercase; 
    font-size: 0.75rem; 
    max-width: 50%;
}

#navbarInure .menu-option .dropdown-menu {
    min-width: auto;
}