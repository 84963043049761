#footer {
    background-color: #CCC;
}

#footer .logo-footer {
    width: 5.21rem; 
    height: 0.84rem;
    content: url('/src/assets/img/logo_footer.svg');
}

#footer .logo2-footer {
    width: 6.85rem; 
    height: 7.7rem;
    content: url('/src/assets/img/logo2_footer.svg');
}

#footer .logo-footer .content {

    border-bottom: 0.1rem solid #B3B3B3;
}

#footer .content .services .link2,
#footer .content .resources .link2,
#footer .content .inures .link2 {
    color: #2F2E3E !important; 
    font-family: Blogger Sans;
}

#footer .content .services .services-title,
#footer .content .resources .resources-title,
#footer .content .inures .inures-title {
    font-family:Montserrat; 
    font-size: 1rem;
}

#footer #legal-links .link2 {
    color: #2F2E3E !important; 
    font-family: Blogger Sans;
}

#footer #social #social_fb {
    width: 1.4rem; 
    height: 1.4rem;
    content: url('/src/assets/img/social_fb.svg')
}

#footer #social #social_ig {
    width: 1.4rem; 
    height: 1.4rem;
    content: url('/src/assets/img/social_ig.svg')
}

#footer #social #social_yt {
    width: 1.4rem; 
    height: 1.4rem;
    content: url('/src/assets/img/social_yt.svg')
}

#footer #social #social_tw {
    width: 1.4rem; 
    height: 1.4rem;
    content: url('/src/assets/img/social_tw.svg')
}

#footer #social #social_in {
    width: 1.4rem; 
    height: 1.4rem;
    content: url('/src/assets/img/social_in.svg')
}

#footer #copyright {
    background-color: #C4C4C4;
}

#footer #copyright span {
    font-family: Blogger Sans;
}