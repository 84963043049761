@font-face {
    font-family: 'Blogger Sans';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Blogger_Sans.otf');
    /* src: url('./fonts/Blogger_Sans-Bold.otf'),
         url('./fonts/Blogger_Sans-Bold_Italic.otf'),
         url('./fonts/Blogger_Sans-Italic.otf'); */
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Montserrat-Bold_0.otf');
}

#scrollToTop {
    position:fixed;
    bottom: 3.5rem;
    right: 2.5rem;
    z-index: 999;
    display: none;
}

.modal .modal-dialog .modal-title {
    font-family: Montserrat;
}

.navbar-brand {
    margin-left: 0 !important;
}

.navbar-brand img {
    margin-left: 0 !important;
}

input {
    font-family: Blogger Sans !important;
    font-size: 0.8rem !important;
    border-width: 0 !important;
}

input::placeholder {
    font-weight: normal !important;
    font-size: .8rem !important;
    font-style: italic !important;
}

.content {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 0.4s;
}

.fade-1 {
    animation: 1s fade-1;
    transition: all 0.5s;
}

@keyframes fade-1 {
    0% {opacity: 0; transform: translate(0, 9vh);}
    50% {opacity: 0; transform: translate(0, 9vh);}
    100% {opacity: 1; transform: translate(0, 0);}
}

.fade-2 {
    animation: 1.25s fade-2;
    transition: all 0.5s;
}

@keyframes fade-2 {
    0% {opacity: 0; transform: translate(0, 9vh);}
    75% {opacity: 0; transform: translate(0, 9vh);}
    100% {opacity: 1; transform: translate(0, 0);}
}

.fade-3 {
    animation: 1.5s fade-2;
    transition: all 0.5s;
}

@keyframes fade-3 {
    0% {opacity: 0; transform: translate(0, 9vh);}
    100% {opacity: 0; transform: translate(0, 9vh);}
    100% {opacity: 1; transform: translate(0, 0);}
}

.after-1 {
    transition-delay: 0.1s !important;
    animation-delay: 0.1s;
}

.after-2 {
    transition-delay: 0.2s !important;
    animation-delay: 0.2s;
}

.after-3 {
    transition-delay: 0.3s !important;
    animation-delay: 0.3s;
}

.layer-slide-right {
    animation: 0.5s slide-right;
    transition: all 0.4s;
}

@keyframes slide-right {
    0% {transform: translate(50vw, 0);}
    100% {transform: translate(0, 0);}
}

.fadeTop {
    opacity: 0; 
    transform: translate(0, 9vh);
    transition: all 0.6s;
}

.visible {
    opacity: 1;
    transform: translate(0, 0);
}
  

.link span {
    position: relative;
}
  
.link span::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background-color: #CCC;
    z-index: 1;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
}
  
.link span:hover::before {
    transform: scaleX(1);
}

.link2 {
    position: relative;
    padding-bottom: 0.1rem !important;
}

.link2::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background-color: #2F2E3E;
    z-index: 1;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
}
  
.link2:hover::before {
    transform: scaleX(1);
}

.angle {
    z-index: 400 !important;
}

.angle-white {
    z-index: 399 !important;
}

.angle:after {
    content: '';
	position: relative;
	top: 1.86rem;
    left: 0;
	width: 0;
	height: 0;
    border-bottom: 1.88rem solid transparent;
    border-left: 100vw solid #22132B;
    clear: both;
    margin-top: -1.85rem;
}

.angle-white:after {
    content: '';
	position: relative;
	top: 1.86rem;
    left: 0;
	width: 0;
	height: 0;
    border-bottom: 1.88rem solid transparent;
    border-left: 100vw solid #FFF;
    clear: both;
    margin-top: -1.86rem;
}

.section {
    max-width: 50rem;
    transition: all 0.4s;
}

.hero {
    background:url(img/hero_bg.svg) #22132B center/100% no-repeat;
    padding-top: 6.5rem;
}

.hero-logo {
    display: none;
}

.title {
    font-family: Montserrat;
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
}

.title2 {
    font-family: Montserrat;
    font-size: 1.4rem;
    font-weight: bold;
}

.subtitle {
    font-family: Blogger Sans;
    font-size: .8rem;
    font-weight: normal;
    text-transform: uppercase;
}

.text-hero {
    font-family: Blogger Sans;
    font-size: .8rem;
    font-weight: normal;
    margin-left: 0 !important;
}

.img-hero {
    width: 23.77rem;
    height: 16.88rem;
    margin-right: 0 !important;
    margin-top: -7rem !important;
    margin-bottom: -2.41rem !important;
}

#start-form {
    margin-left: 0 !important;
    margin-top: -4.5rem !important;
    margin-bottom: 2rem !important;
}

.start-input {
    padding-left: 0 !important;
}

ul {
    margin-top: 0;
}

ul li{
    margin-left: 0;
}

#legal-links {
    padding-left: 0 !important;
}

#legal-links a{
    margin-left: 0 !important;
}

.inercia-up1 {
    animation: inercia-up1 0.4s;
    -webkit-animation: inercia-up1 0.4s;
}
@keyframes inercia-up1 {
    0% {transform: translateY(0);}
    30% {transform: translateY(-4vh);}
    100% {transform: translateY(0);}
}

.inercia-up2 {
    animation: inercia-up2 0.5s;
    -webkit-animation: inercia-up2 0.5s;
}
@keyframes inercia-up2 {
    0% {transform: translateY(0);}
    35% {transform: translateY(-4vh);}
    100% {transform: translateY(0);}
}

.inercia-up3 {
    animation: inercia-up3 0.6s;
    -webkit-animation: inercia-up3 0.6s;
}
@keyframes inercia-up3 {
    0% {transform: translateY(0);}
    40% {transform: translateY(-4vh);}
    100% {transform: translateY(0);}
}
/*_____________________________________*/

.inercia-down1 {
    animation: inercia-down1 0.6s;
    -webkit-animation: inercia-down1 0.4s;
}
@keyframes inercia-down1 {
    0% {transform: translateY(0);}
    30% {transform: translateY(4vh);}
    100% {transform: translateY(0);}
}

.inercia-down2 {
    animation: inercia-down2 0.5s;
    -webkit-animation: inercia-down2 0.5s;
}
@keyframes inercia-down2 {
    0% {transform: translateY(0);}
    35% {transform: translateY(4vh);}
    100% {transform: translateY(0);}
}

.inercia-down3 {
    animation: inercia-down3 0.4s;
    -webkit-animation: inercia-down3 0.6s;
}
@keyframes inercia-down3 {
    0% {transform: translateY(0);}
    40% {transform: translateY(4vh);}
    100% {transform: translateY(0);}
}
/*_______________________________________/*

/* MOBILE */
@media screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: portrait) {

    #navbarInure {
        margin-top: -2rem !important;
    }

    .navbar-brand {
        margin-left: auto !important;
        opacity: 0.2;
    }

    .navbar-brand img {
        margin-left: auto !important;
    }

    .menu button{
        margin-top: 1rem !important;
    }

    .menu-option {
        margin-top: 1rem;
    }
    .hero {
        background:none;
    }

    .hero-logo {
        display: flex !important;
    }

    .img-hero {
        margin-top: -22rem !important;
        opacity: 0.3;
        z-index: 1 !important;
    }

    .title {
        margin-left: auto !important;
        text-align: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        z-index: 502;
    }

    .text-hero {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        z-index: 501;
    }

    #start-form {
        width: 100%;
        margin: auto !important;
        margin-top: 9rem !important;
        margin-left: 0 !important;
        padding-bottom: 2.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        z-index: 503;
    }

    .start-input {
        padding-right: 0 !important;
        margin-top: 1rem !important;
    }

    #start-form button{
        margin-top: 1rem !important;
        width: 100% !important;
    }

    .after-1 {
        transition-delay: 0 !important;
    }
        
    .after-2 {
        transition-delay: 0 !important;
    }

    .after-3 {
        transition-delay: 0 !important;
    }

    textarea {
        min-height: 6.5rem !important;
    }

    .logo-footer {
        margin-left: auto !important;
        margin-bottom: 1rem !important;
    }

    .logo2-footer {
        display: none !important;
    }

    ul li{
        margin-left: auto !important;
        margin-right: auto !important;

    }

    #legal-links {
        padding-left: calc(var(--bs-gutter-x) / 2) !important;
    }

    #legal-links a{
        margin-left: auto !important;
    }

    #social {
        margin-top: 1rem !important;
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }


}