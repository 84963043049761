
#scrollToTop {
    width: 3rem; 
    height: 3rem;
    display: block;
}

#scrollToTop img {
    content: url('/src/assets/img/go_top.svg');
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: 0;
}