#features .title {
    margin-top: 2.5rem !important;
}

/* #features .subtitle {
    
} */

#features .fadeTop .title {
    font-family:Montserrat; 
    font-size: 1rem; 
    color: #2F2E3E;
}

#features .fadeTop .description {
    font-family:Blogger Sans; 
    color: #2F2E3E;
}

#features .fadeTop #ft_profile{
    width: 4rem; 
    height: 4rem;
    content: url('/src/assets/img/ft_profile.svg')
}

#features .fadeTop #ft_invoice{
    width: 4rem; 
    height: 4rem;
    content: url('/src/assets/img/ft_invoice.svg')
}

#features .fadeTop #ft_bank{
    width: 4rem; 
    height: 4rem;
    content: url('/src/assets/img/ft_bank.svg')
}

#features .fadeTop #ft_tax{
    width: 4rem; 
    height: 4rem;
    content: url('/src/assets/img/ft_tax.svg')
}

#features .fadeTop #ft_payroll{
    width: 4rem; 
    height: 4rem;
    content: url('/src/assets/img/ft_payroll.svg')
}

#features .fadeTop #ft_responsive{
    width: 4rem; 
    height: 4rem;
    content: url('/src/assets/img/ft_responsive.svg')
}
