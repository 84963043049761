
#contactUs .title {
    margin-top: 2.5rem !important;
}

#contactUs #personalData input {
    padding: 0.822rem 1rem;
}

#contactUs #message textarea {
    font-size: .8rem; 
    padding: 0.822rem 1rem;
}

#contactUs #button button {
    font-family: Blogger Sans; 
    text-transform: uppercase;
}